<footer>
    <div class="container">
        <div *ngIf="!nuevo" class="row center">
            <div class="col">
                <div class="title">
                    17 objetivos para poner fin a la pobreza, proteger el planeta y garantizar que todas las personas gocen de paz y prosperidad para 2030.
                </div>
            </div>
        </div>
        <div *ngIf="nuevo" class="odss">
            <a routerLink="/ods/1"><img class="ods-image {{(game.isCompleted(1)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-1.png"></a>

            <a routerLink="/ods/2"><img class="ods-image {{(game.isCompleted(2)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-2.png"></a>

            <a routerLink="/ods/3"><img class="ods-image {{(game.isCompleted(3)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-3.png"></a>

            <a routerLink="/ods/4"><img class="ods-image {{(game.isCompleted(4)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-4.png"></a>

            <a routerLink="/ods/5"><img class="ods-image {{(game.isCompleted(5)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-5.png"></a>

            <a routerLink="/ods/6"><img class="ods-image {{(game.isCompleted(6)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-6.png"></a>

            <a routerLink="/ods/7"><img class="ods-image {{(game.isCompleted(7)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-7.png"></a>

            <a routerLink="/ods/8"><img class="ods-image {{(game.isCompleted(8)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-8.png"></a>

            <a routerLink="/ods/9"><img class="ods-image {{(game.isCompleted(9)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-9.png"></a>

            <a routerLink="/ods/10"><img class="ods-image {{(game.isCompleted(10)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-10.png"></a>

            <a routerLink="/ods/11"><img class="ods-image {{(game.isCompleted(11)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-11.png"></a>

            <a routerLink="/ods/12"><img class="ods-image {{(game.isCompleted(12)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-12.png"></a>

            <a routerLink="/ods/13"><img class="ods-image {{(game.isCompleted(13)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-13.png"></a>

            <a routerLink="/ods/14"><img class="ods-image {{(game.isCompleted(14)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-14.png"></a>

            <a routerLink="/ods/15"><img class="ods-image {{(game.isCompleted(15)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-15.png"></a>

            <a routerLink="/ods/16"><img class="ods-image {{(game.isCompleted(16)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-16.png"></a>

            <a routerLink="/ods/17"><img class="ods-image {{(game.isCompleted(17)) ? 'completed' : 'no-completed'}}" src="/assets/images/ods/goal-17.png"></a>
        </div>
    </div>
</footer>