import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-simple-modal',
    templateUrl: './simple-modal.component.html',
    styleUrls: ['./simple-modal.component.css']
})
export class SimpleModalComponent implements OnInit {

    @Input() text: string;

    @ViewChild('content') prueba;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {

    }

    open(text = '') {
        this.text = text;
        this.modalService.open(this.prueba, {ariaLabelledBy: 'modal-basic-title', windowClass: 'simple-modal'});
    }

}




