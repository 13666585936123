<app-header></app-header>

<div class="container">
    <div class="row">
        <div class="col">
            <a routerLink="/roullete" routerLinkActive="active">
                <img src="/assets/images/roullete/ruleta-full-question.png">
            </a>
        </div>
    </div>
</div>

<app-footer></app-footer>