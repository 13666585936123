<footer>
    <div class="container">
        <div class="row center">
            <div class="col-md"></div>
            <div class="col-md">
                <img class="guadalajara" src="/assets/images/logos/guadalajara.png">
            </div>
            <div class="col-md">
                <img class="murcia" src="/assets/images/logos/murcia.jpg">
            </div>
            <div class="col-md">
                <img class="toledo" src="/assets/images/logos/toledo.jpg">
            </div>
            <div class="col-md">
                <img class="vicente" src="/assets/images/logos/vicenteFerrer.png">
            </div>
            <div class="col-md"></div>
        </div>
    </div>
</footer>