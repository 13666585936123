import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DificultyComponent } from './dificulty/dificulty.component';
import { InitComponent } from './init/init.component';
import { RoulleteComponent } from './roullete/roullete.component';
import { ObjectiveComponent } from './objective/objective.component';
import { QuestionComponent } from './question/question.component';
import { FactComponent } from './fact/fact.component';
import { EndGameComponent } from './end-game/end-game.component';
import { MainGameComponent } from './main-game/main-game.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { GameHeaderComponent } from './layout/game-header/game-header.component';
import { GameFooterComponent } from './layout/game-footer/game-footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngb-modal';
import { SimpleModalComponent } from './layout/simple-modal/simple-modal.component';
import { FinalModalComponent } from './layout/final-modal/final-modal.component';
import { LogoVicenteComponent } from './layout/logo-vicente/logo-vicente.component';

@NgModule({
  declarations: [
    AppComponent,
    DificultyComponent,
    InitComponent,
    RoulleteComponent,
    ObjectiveComponent,
    QuestionComponent,
    FactComponent,
    EndGameComponent,
    MainGameComponent,
    FooterComponent,
    HeaderComponent,
    GameHeaderComponent,
    GameFooterComponent,
    SimpleModalComponent,
    FinalModalComponent,
    LogoVicenteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ModalModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
