<header style="background-color: {{color}};">
    <div class="container">
        <div class="row">
            <div class="col">
                <a routerLink="/roullete" routerLinkActive="active">
                    <img class="back" src="/assets/images/top-back.png">
                </a>
            </div>
            <div class="col" style="text-align: center;">
                <img class="ods-image" src="/assets/images/ods/goal-{{ods}}.png">
            </div>
            <div class="col">
            </div>
        </div>
    </div>
</header>

<div class="container main">
    <div class="row">
        <div class="col-lg">
            <div class="custom-text-big d-flex" style="border-color: {{color}};">
                <div class="align-self-center" [innerHTML]="questionData.text"></div>
            </div>
        </div>
        <div class="col-lg">
            <div class="custom-text-question {{selected == 0 && !ok && !fail ? 'selected' : ''}} {{selected == 0 && ok ? 'ok' : ''}} {{selected == 0 && fail ? 'fail' : ''}}" style="border-color: {{color}};" [innerHTML]="questionData.answers[0]" (click)="select(0)">
            </div>
            <div class="custom-text-question {{selected == 1 && !ok && !fail ? 'selected' : ''}} {{selected == 1 && ok ? 'ok' : ''}} {{selected == 1 && fail ? 'fail' : ''}}" style="border-color: {{color}};" [innerHTML]="questionData.answers[1]" (click)="select(1)">
            </div>
            <div class="custom-text-question {{selected == 2 && !ok && !fail ? 'selected' : ''}} {{selected == 2 && ok ? 'ok' : ''}} {{selected == 2 && fail ? 'fail' : ''}}" style="border-color: {{color}};" [innerHTML]="questionData.answers[2]" (click)="select(2)">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="selected != -1 && !ok && !fail">
        <div class="col">
            <button class="custom-btn-2" (click)="validate()">
                <fa-icon [icon]="faCheck"></fa-icon> VALIDAR
            </button>
        </div>
    </div>
    <div class="row" *ngIf="ok">
        <div class="col">
            <button class="custom-btn-2" (click)="continue()">
                CONTINUAR <fa-icon [icon]="faArrow"></fa-icon>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="fail">
        <div class="col">
            <button class="custom-btn-2" (click)="return()">
                <fa-icon [icon]="faReturn"></fa-icon> VOLVER
            </button>
            <button class="custom-btn-2" (click)="reload()">
                REPETIR <fa-icon [icon]="faRepeat"></fa-icon>
            </button>
        </div>
    </div>
</div>



<app-logo-vicente></app-logo-vicente>