import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faArrowLeft, faArrowRight, faCheckCircle, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  color = "#ffffff";
  ods = 0;
  odsData : any = {};
  questionData : any = {};
  animationScale = 0.0;
  showText = false;
  faCheck = faCheckCircle;
  faArrow = faArrowRight;
  faReturn = faArrowLeft;
  faRepeat = faUndoAlt;

  selected = -1;
  ok = false;
  fail = false;

  isPrevew = false;

  constructor(public game: GameStatsService,
    private router: Router,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
    this.ods = parseInt(this.route.snapshot.paramMap.get('ods'));

    if (this.game.isCompleted(this.ods)){
      this.isPrevew = true;
      this.ok = true;
      this.selected = this.game.getPrevewAnswer(this.ods);
    }

    this.odsData = this.game.getODSData(this.ods);

    if (!this.isPrevew)
      this.questionData = this.game.getODSQuestion(this.ods);
    else
      this.questionData = this.game.getODSPrevewQuestion(this.ods);
      
    this.color = this.odsData.color;

  }

  select(question) {
    if (!this.ok && !this.fail) {
      this.selected = question;
    }
  }

  validate() {
    if (this.game.answer(this.ods, this.selected)) {
      this.ok = true;
    } else {
      this.fail = true;
    }
  }

  continue() {
    if (this.isPrevew) {
      if (this.game.nextPrevewProgress(this.ods)) {
        this.router.navigate(["/roullete"]);
      } else {
        this.router.navigate(["/ods/" + this.ods + "/question/fact"]);
      }

    } else if (this.game.isCompleted(this.ods)) {
      this.router.navigate(["/roullete"]);
    } else {
      this.router.navigate(["/ods/" + this.ods + "/question/fact"]);
    }
  }

  reload() {
    this.selected = -1;
    this.ok = false;
    this.fail = false;
  }

  return() {
    this.router.navigate(["/roullete"])
  }
}
