import { Component, OnInit, ViewChild } from '@angular/core';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-main-game',
  templateUrl: './main-game.component.html',
  styleUrls: ['./main-game.component.css']
})
export class MainGameComponent implements OnInit {

  @ViewChild('simpleModal') simpleModal;
  @ViewChild('finalModal') finalModal;

  constructor(public game: GameStatsService) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
    setTimeout(() => {
      if (this.game.isGameEnded()) {
        this.finalModal.open("¡Enhorabuena! Has completado la ruleta de los Objetivos de Desarrollo Sostenible.<br><br>" +
           "Te esperamos en nuestra web donde encontrarás más recursos educativos.<br><br>" +
           "<a class='custom-btn' href='https://fvf-manabadi.org/'>MÁS RECURSOS EDUCATIVOS</a>");
      } else {
        const modal = this.game.getShowModal();

        if (modal == 1) {
          this.simpleModal.open("¡BRAVO!<br> Selecciona otro ODS");
        } else if (modal == 2) {
          this.simpleModal.open("¡UPSS!<br> Selecciona otro ODS");
        }
      }

      this.game.resetShowModal();
    }, 1000);
  }

}
