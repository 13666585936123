import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-roullete',
  templateUrl: './roullete.component.html',
  styleUrls: ['./roullete.component.css']
})
export class RoulleteComponent implements OnInit {
  maxScale = 1.5
  minScale = 0.7
  withDelta = 1700;
  minWidth = 300;
  scale = 1.5;
  points = 0;

  endGame = false;
  nuevo = false

  constructor(public game: GameStatsService,
    private router: Router) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
    if (this.game.isGameEnded()) {
      this.endGame = true;
      this.nuevo = true;
    }else if (this.game.isAnyOdsCompleted()) {
      this.nuevo = true;
      this.points = this.game.getPoints();
    }
    this.updateSize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSize(event.target.innerWidth);
  }

  updateSize(width) {
    const diff = width - this.minWidth;
    console.log(diff)

    if (diff > this.withDelta) {
      this.scale = this.maxScale
    } else if (diff < 0) {
      this.scale = this.minScale
    } else {
      this.scale = (this.maxScale - this.minScale) * (diff / this.withDelta) + this.minScale
    }
    console.log(this.scale)
  }

  isNotCompleted(ods) {
    return !this.game.isCompleted(ods)
  }

  selected(ods) {
    this.router.navigate(['/ods/' + ods]);
  }

  
  selectedCompleted(ods) {
    this.router.navigate(['/ods/' + ods]);
  }

  reset() {
    this.game.reset(); 
    this.router.navigate(['/level']);
  }

}
