<app-header></app-header>

<div class="container">
    <div class="row">
        <div class="col">
            <h1>Selecciona tu nivel</h1>
            <div>
                <button class="custom-btn" (click)="dificultySelected(1)">
                    PRIMARIA
                </button>
                <button class="custom-btn" (click)="dificultySelected(2)">
                    SECUNDARIA
                </button>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>