import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as data from './data.json';

@Injectable({
  providedIn: 'root'
})
export class GameStatsService {
  gameData: any = (data as any).default;
  dificulty = 0;
  questionsStr = "questions-1"; 

  showModal = 0;

  nQuestions = 3;

  points = 0;

  progress = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  previewProgress = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  tries = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
  ];

  public localExpire = 24 * 60 * 60 * 1000;

  constructor(private router: Router) { 
    this.loadData();
  }

  checkRedirect() {
    if (this.router.url === '/level') {
      if (this.dificulty > 0) {
        this.router.navigate(["/init"])
      }
    } else {
      if (this.dificulty == 0) {
        this.router.navigate(["/level"])
      }
    }
  }


  setLocal(key, value) {
    const now = new Date();

    const dat = {
        value,
        expiry: now.getTime() + this.localExpire
    };

    localStorage.setItem(key, JSON.stringify(dat));
  }

  unsetLocal(key) {
    localStorage.removeItem(key);
  }

  getLocal(key) {
    const json = localStorage.getItem(key);

    if (!json) {
        return null;
    }

    const dat = JSON.parse(json);
    const now = new Date();

    if (now.getTime() > dat.expiry) {
        this.unsetLocal(key);
        return null;
    }
    return dat.value;
  }

  saveData() {
    // this.setLocal("dificulty", this.dificulty);
    // this.setLocal("points", this.points);
    // this.setLocal("questionsStr", this.questionsStr);
    // this.setLocal("progress", this.progress);
    // this.setLocal("tries", this.tries);
  }

  loadData() {
    let dat: any;

    this.dificulty = (dat = this.getLocal("dificulty")) ? dat : this.dificulty;
    this.points = (dat = this.getLocal("points")) ? dat : this.points;
    this.questionsStr = (dat = this.getLocal("questionsStr")) ? dat : this.questionsStr;
    this.progress = (dat = this.getLocal("progress")) ? dat : this.progress;
    this.tries = (dat = this.getLocal("tries")) ? dat : this.tries;
  }


  setDificulty(dificulty) {
    this.dificulty = dificulty
    this.questionsStr = "questions-" + dificulty.toString();
    this.saveData();
  }

  getODSData(ods) {
    return this.gameData[ods - 1];
  }

  getODSQuestion(ods) {
    const nextQuestion = this.progress[ods - 1]
    return this.getODSData(ods)[this.questionsStr][nextQuestion];
  }

  getODSPrevewQuestion(ods) {
    const nextQuestion = this.previewProgress[ods - 1]
    return this.getODSData(ods)[this.questionsStr][nextQuestion];
  }

  nextPrevewProgress(ods) {
    this.previewProgress[ods - 1] = this.previewProgress[ods - 1] + 1;

    if (this.previewProgress[ods - 1] >= 3){
      this.previewProgress[ods - 1] = 0;
      return true;
    }
    return false;
  }

  isCompleted(ods) {
    return this.progress[ods - 1] >= this.nQuestions;
  }

  isGameEnded() {
    return this.progress.reduce((a, b) => a + b, 0) >= 51;
  }

  getODSProgress(ods) {
    return this.progress[ods - 1];
  }
  
  getODSPrevewProgress(ods) {
    return this.previewProgress[ods - 1];
  }

  getPoints() {
    return this.points;
  }

  isAnyOdsCompleted() {
    let BreakException = {};
     
    try {
      this.progress.forEach(element => {
        if (element >= 3) {
          throw BreakException;
        }
      });
    } catch (e) {
      if (e == BreakException) {
        return true;
      }
    }

    return false;
  }

  getPrevewAnswer(ods) {
    const question = this.getODSPrevewQuestion(ods);
    return question.correct;
  }

  answer(ods, response) {
    const question = this.getODSQuestion(ods);
    
    if (response == question.correct) {
      switch (this.tries[ods - 1]) {
        case 0: {
          this.points += 100;
          break;
        }
        case 1: {
          this.points += 50;
          break;
        }
        case 2: {
          this.points += 10;
          break;
        }
      }

      this.progress[ods - 1] = this.progress[ods - 1] + 1
      this.tries[ods - 1] = 0;
      this.saveData();
      this.showModal = 1;
      return true;
    }

    this.tries[ods - 1] ++;
    this.saveData();
    this.showModal = 2;
    return false;
  }

  getFact(ods) {
    return this.getODSData(ods)["facts-" + this.dificulty][this.getODSProgress(ods) - 1];
  }

  getPrevewFact(ods) {
    return this.getODSData(ods)["facts-" + this.dificulty][this.getODSPrevewProgress(ods) - 1];
  }

  getShowModal() {
    return this.showModal;
  }

  resetShowModal() {
    this.showModal = 0;
  }

  reset() {
    this.unsetLocal("dificulty");
    this.unsetLocal("points");
    this.unsetLocal("questionsStr");
    this.unsetLocal("progress");
    this.unsetLocal("tries");

    this.dificulty = 0;
    this.questionsStr = "questions-1"; 
    this.showModal = 0;
    this.nQuestions = 3;
    this.points = 0;
    this.progress = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ];
    this.tries = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ];
  }
}
