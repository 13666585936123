import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GameStatsService } from 'src/app/game-stats.service';

@Component({
  selector: 'app-game-footer',
  templateUrl: './game-footer.component.html',
  styleUrls: ['./game-footer.component.css']
})
export class GameFooterComponent implements OnInit {
  nuevo = false

  constructor(public game: GameStatsService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.game.isAnyOdsCompleted()) {
      this.nuevo = true;
    }
  }

  

}
