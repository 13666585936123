import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DificultyComponent } from './dificulty/dificulty.component';
import { EndGameComponent } from './end-game/end-game.component';
import { FactComponent } from './fact/fact.component';
import { InitComponent } from './init/init.component';
import { MainGameComponent } from './main-game/main-game.component';
import { ObjectiveComponent } from './objective/objective.component';
import { QuestionComponent } from './question/question.component';

const routes: Routes = [
  { path: '', redirectTo: 'level', pathMatch: 'full'},
  { path: 'level', component: DificultyComponent},
  { path: 'init', component: InitComponent},
  { path: 'roullete', component: MainGameComponent},
  { path: 'ods/:ods', component: ObjectiveComponent},
  { path: 'ods/:ods/question', component: QuestionComponent},
  { path: 'ods/:ods/question/fact', component: FactComponent},
  { path: 'end', component: EndGameComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
