<div>
    <div class="roullete" style="transform: scale({{scale}});">
        <img *ngIf="scale > 1.0 && !nuevo" src="/assets/images/selecciona-primer-objetivo.png" style="width:308px; position: absolute; top: 50px; left: -308px">
        <div *ngIf="nuevo && !endGame" class="points" style="width:308px; position: absolute; top: 188px; left: 64px">
            {{points}} pts.
        </div>
        <div *ngIf="endGame" class="points" style="width:308px; position: absolute; top: 188px; left: 64px">
            <button class="custom-btn" (click)="reset()">Volver a jugar</button>
        </div>
        <img *ngIf="isNotCompleted(1)" (click)="selected(1)" id="pruleta-1" src="/assets/images/roullete/ruleta-1.png" class="ods" style="width:67px; height:111px; position: absolute; top: 0px; left: calc(512px - 297px)" alt="objetivo 1">
        <img *ngIf="!isNotCompleted(1)" (click)="selectedCompleted(1)" id="pruleta-1" src="/assets/images/roullete/ruleta-1.png" class="ods complete" style="width:67px; height:111px; position: absolute; top: 0px; left: calc(512px - 297px)" alt="objetivo 1">

        <img *ngIf="isNotCompleted(2)" (click)="selected(2)" id="pruleta-2" src="/assets/images/roullete/ruleta-2.png" class="ods" style="width:88px; height:103px; position: absolute; top: 26px; left: calc(555px - 297px)" alt="objetivo 2">
        <img *ngIf="!isNotCompleted(2)" (click)="selected(2)" id="pruleta-2" src="/assets/images/roullete/ruleta-2.png" class="ods complete" style="width:88px; height:103px; position: absolute; top: 26px; left: calc(555px - 297px)" alt="objetivo 2">

        <img *ngIf="isNotCompleted(3)" (click)="selected(3)" id="pruleta-3" src="/assets/images/roullete/ruleta-3.png" class="ods" style="width:99px; height:90px; position: absolute; top: 69px; left: calc(592px - 297px)" alt="objetivo 3">
        <img *ngIf="!isNotCompleted(3)" (click)="selected(3)" id="pruleta-3" src="/assets/images/roullete/ruleta-3.png" class="ods complete" style="width:99px; height:90px; position: absolute; top: 69px; left: calc(592px - 297px)" alt="objetivo 3">

        <img *ngIf="isNotCompleted(4)" (click)="selected(4)" id="pruleta-4" src="/assets/images/roullete/ruleta-4.png" class="ods" style="width:99px; height:69px; position: absolute; top: 132px; left: calc(614px - 297px)" alt="objetivo 4">
        <img *ngIf="!isNotCompleted(4)" (click)="selected(4)" id="pruleta-4" src="/assets/images/roullete/ruleta-4.png" class="ods complete" style="width:99px; height:69px; position: absolute; top: 132px; left: calc(614px - 297px)" alt="objetivo 4">

        <img *ngIf="isNotCompleted(5)" (click)="selected(5)" id="pruleta-5" src="/assets/images/roullete/ruleta-5.png" class="ods" style="width:95px; height:64px; position: absolute; top:203px; left: calc(621px - 297px)" alt="objetivo 5">
        <img *ngIf="!isNotCompleted(5)" (click)="selected(5)" id="pruleta-5" src="/assets/images/roullete/ruleta-5.png" class="ods complete" style="width:95px; height:64px; position: absolute; top:203px; left: calc(621px - 297px)" alt="objetivo 5">

        <img *ngIf="isNotCompleted(6)" (click)="selected(6)" id="pruleta-6" src="/assets/images/roullete/ruleta-6.png" class="ods" style="width:100px; height:81px; position: absolute; top:252px; left: calc(606px - 297px)" alt="objetivo 6">
        <img *ngIf="!isNotCompleted(6)" (click)="selected(6)" id="pruleta-6" src="/assets/images/roullete/ruleta-6.png" class="ods complete" style="width:100px; height:81px; position: absolute; top:252px; left: calc(606px - 297px)" alt="objetivo 6">

        <img *ngIf="isNotCompleted(7)" (click)="selected(7)" id="pruleta-7" src="/assets/images/roullete/ruleta-7.png" class="ods" style="width:95px; height:98px; position: absolute; top:288px; left: calc(576px - 297px)" alt="objetivo 7">
        <img *ngIf="!isNotCompleted(7)" (click)="selected(7)" id="pruleta-7" src="/assets/images/roullete/ruleta-7.png" class="ods complete" style="width:95px; height:98px; position: absolute; top:288px; left: calc(576px - 297px)" alt="objetivo 7">

        <img *ngIf="isNotCompleted(8)" (click)="selected(8)" id="pruleta-8" src="/assets/images/roullete/ruleta-8.png" class="ods" style="width:75px; height:100px; position: absolute; top:316px; left: calc(539px - 297px)" alt="objetivo 8">
        <img *ngIf="!isNotCompleted(8)" (click)="selected(8)" id="pruleta-8" src="/assets/images/roullete/ruleta-8.png" class="ods complete" style="width:75px; height:100px; position: absolute; top:316px; left: calc(539px - 297px)" alt="objetivo 8">

        <img *ngIf="isNotCompleted(9)" (click)="selected(9)" id="pruleta-9" src="/assets/images/roullete/ruleta-9.png" class="ods" style="width:66px; height:95px; position: absolute; top: 328px; left: calc(480px - 297px)" alt="objetivo 9">
        <img *ngIf="!isNotCompleted(9)" (click)="selected(9)" id="pruleta-9" src="/assets/images/roullete/ruleta-9.png" class="ods complete" style="width:66px; height:95px; position: absolute; top: 328px; left: calc(480px - 297px)" alt="objetivo 9">

        <img *ngIf="isNotCompleted(10)" (click)="selected(10)" id="pruleta-10" src="/assets/images/roullete/ruleta-10.png" class="ods" style="width:76px; height:100px; position: absolute; top: 315px; left: calc(412px - 297px)" alt="objetivo 10">
        <img *ngIf="!isNotCompleted(10)" (click)="selected(10)" id="pruleta-10" src="/assets/images/roullete/ruleta-10.png" class="ods complete" style="width:76px; height:100px; position: absolute; top: 315px; left: calc(412px - 297px)" alt="objetivo 10">

        <img *ngIf="isNotCompleted(11)" (click)="selected(11)" id="pruleta-11" src="/assets/images/roullete/ruleta-11.png" class="ods" style="width:94px; height:96px; position: absolute; top: 289px; left: calc(355px - 297px)" alt="objetivo 11">
        <img *ngIf="!isNotCompleted(11)" (click)="selected(11)" id="pruleta-11" src="/assets/images/roullete/ruleta-11.png" class="ods complete" style="width:94px; height:96px; position: absolute; top: 289px; left: calc(355px - 297px)" alt="objetivo 11">

        <img *ngIf="isNotCompleted(12)" (click)="selected(12)" id="pruleta-12" src="/assets/images/roullete/ruleta-12.png" class="ods" style="width:100px; height:83px; position: absolute; top: 250px; left: calc(318px - 297px)" alt="objetivo 12">
        <img *ngIf="!isNotCompleted(12)" (click)="selected(12)" id="pruleta-12" src="/assets/images/roullete/ruleta-12.png" class="ods complete" style="width:100px; height:83px; position: absolute; top: 250px; left: calc(318px - 297px)" alt="objetivo 12">

        <img *ngIf="isNotCompleted(13)" (click)="selected(13)" id="pruleta-13" src="/assets/images/roullete/ruleta-13.png" class="ods" style="width:104px; height:66px; position: absolute; top: 200px; left: calc(297px - 297px)" alt="objetivo 13">
        <img *ngIf="!isNotCompleted(13)" (click)="selected(13)" id="pruleta-13" src="/assets/images/roullete/ruleta-13.png" class="ods complete" style="width:104px; height:66px; position: absolute; top: 200px; left: calc(297px - 297px)" alt="objetivo 13">

        <img *ngIf="isNotCompleted(14)" (click)="selected(14)" id="pruleta-14" src="/assets/images/roullete/ruleta-14.png" class="ods" style="width:100px; height:71px; position: absolute; top: 130px; left: calc(309px - 297px)" alt="objetivo 14">
        <img *ngIf="!isNotCompleted(14)" (click)="selected(14)" id="pruleta-14" src="/assets/images/roullete/ruleta-14.png" class="ods complete" style="width:100px; height:71px; position: absolute; top: 130px; left: calc(309px - 297px)" alt="objetivo 14">

        <img *ngIf="isNotCompleted(15)" (click)="selected(15)" id="pruleta-15" src="/assets/images/roullete/ruleta-15.png" class="ods" style="width:100px; height:92px; position: absolute; top: 69px; left: calc(333px - 297px)" alt="objetivo 15">
        <img *ngIf="!isNotCompleted(15)" (click)="selected(15)" id="pruleta-15" src="/assets/images/roullete/ruleta-15.png" class="ods complete" style="width:100px; height:92px; position: absolute; top: 69px; left: calc(333px - 297px)" alt="objetivo 15">

        <img *ngIf="isNotCompleted(16)" (click)="selected(16)" id="pruleta-16" src="/assets/images/roullete/ruleta-16.png" class="ods" style="width:86px; height:100px; position: absolute; top: 29px; left: calc(380px - 297px)" alt="objetivo 16">
        <img *ngIf="!isNotCompleted(16)" (click)="selected(16)" id="pruleta-16" src="/assets/images/roullete/ruleta-16.png" class="ods complete" style="width:86px; height:100px; position: absolute; top: 29px; left: calc(380px - 297px)" alt="objetivo 16">

        <img *ngIf="isNotCompleted(17)" (click)="selected(17)" id="pruleta-17" src="/assets/images/roullete/ruleta-17.png" class="ods" style="width:69px; height:110px; position: absolute; top: 0px; left: calc(443px - 297px)" alt="objetivo 17">
        <img *ngIf="!isNotCompleted(17)" (click)="selected(17)" id="pruleta-17" src="/assets/images/roullete/ruleta-17.png" class="ods complete" style="width:69px; height:110px; position: absolute; top: 0px; left: calc(443px - 297px)" alt="objetivo 17">

    </div>
</div>