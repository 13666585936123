import { Component, OnInit } from '@angular/core';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.css']
})
export class InitComponent implements OnInit {

  constructor(private game: GameStatsService) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
  }

}
