import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowAltCircleLeft, faArrowLeft, faArrowRight, faCheckCircle, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-fact',
  templateUrl: './fact.component.html',
  styleUrls: ['./fact.component.css']
})
export class FactComponent implements OnInit {
  color = "#ffffff";
  ods = 0;
  odsData : any = {};
  faArrow = faArrowRight;
  image = "";
  fact = "";

  selected = -1;
  ok = false;
  fail = false;

  isPrevew = false;

  constructor(public game: GameStatsService,
    private router: Router,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
    let progress = 0;
    this.ods = parseInt(this.route.snapshot.paramMap.get('ods'));

    if (this.game.isCompleted(this.ods)){
      this.isPrevew = true;
      this.ok = true;
      progress = this.game.getODSPrevewProgress(this.ods);
    } else {
      progress = this.game.getODSProgress(this.ods);
    }

    this.odsData = this.game.getODSData(this.ods);

    this.color = this.odsData.color;
    if (progress > 0 && progress < 3) {
      if (this.isPrevew)
        this.fact = this.game.getPrevewFact(this.ods);
      else
        this.fact = this.game.getFact(this.ods);
        
      this.image = "/assets/images/facts/" + this.ods + "/" + progress + ".png";
    } else {
      this.fact = this.game.getFact(this.ods);
      this.image = "/assets/images/facts/" + this.ods + "/1.png";
    }
  }


  continue() {
    this.router.navigate(["/ods/" + this.ods + "/question"]);
  }

}
