import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-dificulty',
  templateUrl: './dificulty.component.html',
  styleUrls: ['./dificulty.component.css']
})
export class DificultyComponent implements OnInit {

  constructor(public game: GameStatsService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.game.dificulty > 0) {
      this.router.navigate(['/init']);
    }
  }

  dificultySelected(diff) {
    this.game.setDificulty(diff);
    this.router.navigate(['/init']);
  }
}
