import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-final-modal',
    templateUrl: './final-modal.component.html',
    styleUrls: ['./final-modal.component.css']
})
export class FinalModalComponent implements OnInit {

    @Input() text: string;

    @ViewChild('content') prueba;

    constructor(private modalService: NgbModal) {
    }

    ngOnInit(): void {

    }

    open(text = '') {
        this.text = text;
        this.modalService.open(this.prueba, {ariaLabelledBy: 'modal-basic-title', windowClass: 'final-modal'});
    }

}




