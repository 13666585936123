<header style="background-color: {{color}};">
    <div class="container">
        <div class="row">
            <div class="col">
                <a routerLink="/roullete" routerLinkActive="active">
                    <img class="back" src="/assets/images/top-back.png">
                </a>
            </div>
            <div class="col" style="text-align: center;">
                <img class="ods-image" src="/assets/images/ods/goal-{{ods}}.png">
            </div>
            <div class="col">
            </div>
        </div>
    </div>
</header>

<div class="container main">
    <div class="row">
        <div class="col-lg">
            <div class="custom-text-big d-flex" style="border-color: {{color}};">
                <div class="align-self-center" [innerHTML]="fact"></div>
            </div>
        </div>
        <div class="col-lg">
            <div class="custom-text-big d-flex" style="border-color: {{color}}; background-image: url({{image}})">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <button class="custom-btn-2" (click)="continue()">
                CONTINUAR <fa-icon [icon]="faArrow"></fa-icon>
            </button>
        </div>
    </div>
</div>


<app-logo-vicente></app-logo-vicente>