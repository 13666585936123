<ng-template #content let-modal class="simple-modal">
    <div class="modal-header">
        <!-- <h4 class="modal-title  text-center" id="modal-basic-title">Aviso</h4> -->
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center main" [innerHTML]="text"></div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn acept" (click)="modal.close()">Aceptar</button> -->
    </div>
</ng-template>


<!--<pre>{{ closeResult }}</pre>-->