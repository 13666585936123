import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { GameStatsService } from '../game-stats.service';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css']
})
export class ObjectiveComponent implements OnInit {
  color = "#ffffff";
  ods = 0;
  odsData : any = {};
  animationScale = 0.0;
  showText = false;
  faArrow = faArrowRight;
  animationTranslateY = "0px";

  constructor(public game: GameStatsService,
    private router: Router,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.game.checkRedirect();
    
    this.ods = parseInt(this.route.snapshot.paramMap.get('ods'));

    this.odsData = this.game.getODSData(this.ods);
    this.color = this.odsData.color;

    this.initAnimation();
  }

  initAnimation() {
    setTimeout(() => {
      this.animationScale = 1;

      setTimeout(() => {
        this.showText = true;
      }, 1000);
    }, 1000);
  }

  continue() {
    this.endAnimation();
  }

  endAnimation() {
    this.animationScale = 0.4;

    setTimeout(() => {
      this.animationTranslateY = "-630px";

      setTimeout(() => {
        // if (this.game.isCompleted(this.ods)) {
        //   this.router.navigate(["/roullete"])
        // } else {
        //   this.router.navigate(["/ods/" + this.ods + "/question"])
        // }
        this.router.navigate(["/ods/" + this.ods + "/question"]);
      }, 1000);
    }, 1000);
  }
}
