<header style="background-color: {{color}};">
    <div class="container">
        <div class="row">
            <div class="col">
                <a routerLink="/roullete" routerLinkActive="active">
                    <img class="back" src="/assets/images/top-back.png">
                </a>
            </div>
            <div class="col">
            </div>
            <div class="col">
            </div>
        </div>
    </div>
</header>

<div class="container">
    <div class="row">
        <div class="col">
            <img class="ods-image" src="/assets/images/ods/goal-{{ods}}.png" style="transform: scale({{animationScale}}) translateY({{animationTranslateY}});">
            <div *ngIf="showText" class="custom-text" style="border-color: {{color}};" [innerHTML]="odsData.text">
            </div>
            <button *ngIf="showText" class="custom-btn-2" (click)="continue()">
                CONTINUAR <fa-icon [icon]="faArrow"></fa-icon>
            </button>
        </div>
    </div>
</div>


<app-logo-vicente></app-logo-vicente>